import React from "react";
import { DayOfWeek, Duration, Instant, LocalDate, LocalDateTime, LocalTime, ZoneId, ZoneOffset } from "@js-joda/core";
import { Messages } from "../../../core/api";
import { CheckIcon } from "@chakra-ui/icons";
import { Heading } from "@chakra-ui/react";
import ClockIcon from "../../../shared/icons/ClockIcon";
import styled from "@emotion/styled";

interface Props {
    tickets: Messages["CommCenterTicket"][];
}

const WORKING_DAYS = [DayOfWeek.MONDAY, DayOfWeek.TUESDAY, DayOfWeek.WEDNESDAY, DayOfWeek.THURSDAY, DayOfWeek.FRIDAY];
const START_OF_WORKING_DAY = LocalTime.of(9, 0);
const END_OF_WORKING_DAY = LocalTime.of(17, 0);
const NEW_YORK_TZ = "America/New_York";

const isInWorkingHours = (instant: Instant) => {    
    if (!WORKING_DAYS.includes(LocalDate.ofInstant(instant).dayOfWeek())) {
        return false;
    }
    
    const newYorkTimeOfInstant = instant.atZone(ZoneId.of(NEW_YORK_TZ)).toLocalTime();
    
    return !(newYorkTimeOfInstant.isBefore(START_OF_WORKING_DAY) || newYorkTimeOfInstant.isAfter(END_OF_WORKING_DAY));
}

function getMedian(arrayOfSeconds: number[]){
    if (arrayOfSeconds.length === 0) {
        return;
    }

    arrayOfSeconds.sort((a,b) => {
        return a-b;
    });

    const half = Math.floor(arrayOfSeconds.length / 2);
    
    if (arrayOfSeconds.length % 2 === 1)
        return arrayOfSeconds[half];
    
    return (arrayOfSeconds[half - 1] + arrayOfSeconds[half]) / 2.0;
}

function formatSeconds(seconds: number | undefined) {
    if (seconds === undefined) {
        return "0h 0m 0s";
    }
    
    const date = LocalDateTime.ofEpochSecond(seconds, ZoneOffset.UTC);
    return `${date.hour()}h ${date.minute()}m ${date.second()}s`
}

function getColorFromDuration(seconds: number | undefined) {
    if (seconds === undefined) {
        return "inherit";
    }

    if (seconds <= 30) {
        return "lightgreen";
    }

    if (seconds / 60 <= 10) {
        return "orange";
    }

    return "red";
}

const TicketsStats = (props : Props) => {
    const {
        medianResponseTimeDuringWorkingHours,
        medianResponseTimeDuringOffWorkHours,
        medianTicketResolutionTimeDuringWorkingHours,
        medianTicketResolutionTimeDuringOffWorkHours
    } = (() => {
        const responseTimesDuringWorkingHours :number[] = [];
        const ticketResolutionTimesDuringWorkingHours :number[] = [];
        const responseTimesDuringOffWorkHours :number[] = [];
        const ticketResolutionTimesDuringOffWorkHours :number[] = [];

        for (const ticket of props.tickets) {
          const firstAgencyMemberMessage = ticket.messages.find((message) => message.createdBy.type === "Agency Member");
          if (firstAgencyMemberMessage === undefined) {
            continue;
          }
    
          const firstCaregiverMessage = ticket.messages.find((message) => message.createdBy.type === "Caregiver");
          if (firstCaregiverMessage === undefined) {
            continue;
          }

          const timeUntilFirstResponse = Duration.between(firstCaregiverMessage.createdAt, firstAgencyMemberMessage.createdAt);

          if (isInWorkingHours(firstCaregiverMessage.createdAt)) {
            responseTimesDuringWorkingHours.push(timeUntilFirstResponse.seconds());
            const resolvedStatus = ticket.statusChanges.find((statusChanged) => statusChanged.status === "RESOLVED");
            if (resolvedStatus === undefined) {
                continue;
            }
            
            const timeUntilTicketResolved = Duration.between(firstCaregiverMessage.createdAt, resolvedStatus.createdAt);
            ticketResolutionTimesDuringWorkingHours.push(timeUntilTicketResolved.seconds());
          } else {
            responseTimesDuringOffWorkHours.push(timeUntilFirstResponse.seconds());
            const resolvedStatus = ticket.statusChanges.find((statusChanged) => statusChanged.status === "RESOLVED");
            if (resolvedStatus === undefined) {
                continue;
            }

            const timeUntilTicketResolved = Duration.between(firstCaregiverMessage.createdAt, resolvedStatus.createdAt);
            ticketResolutionTimesDuringOffWorkHours.push(timeUntilTicketResolved.seconds());            
          }
        }        

        return {
            medianResponseTimeDuringWorkingHours: getMedian(responseTimesDuringWorkingHours),
            medianResponseTimeDuringOffWorkHours: getMedian(responseTimesDuringOffWorkHours),
            medianTicketResolutionTimeDuringWorkingHours: getMedian(ticketResolutionTimesDuringWorkingHours),
            medianTicketResolutionTimeDuringOffWorkHours: getMedian(ticketResolutionTimesDuringOffWorkHours)
        }
    })();

    return (
        <TicketsStats.Root>
            <TicketsStats.Section>
                <TicketsStats.Title>
                    <ClockIcon boxSize={18}/>
                    <Heading size="sm">
                        Median time until first response
                    </Heading>
                </TicketsStats.Title>
                <TicketsStats.Details>
                    <TicketsStats.Times>
                        <Heading 
                            size="sm" 
                            textColor={getColorFromDuration(medianResponseTimeDuringWorkingHours)}
                        >
                            {formatSeconds(medianResponseTimeDuringWorkingHours)}
                        </Heading>
                        <Heading size="sm" textColor="blackAlpha.600" fontWeight="normal">
                            Working days
                        </Heading>
                    </TicketsStats.Times>
                    <TicketsStats.Times>
                        <Heading
                            size="sm"
                            textColor={getColorFromDuration(medianResponseTimeDuringOffWorkHours)}
                        >
                            {formatSeconds(medianResponseTimeDuringOffWorkHours)}
                        </Heading>
                        <Heading size="sm" textColor="blackAlpha.600" fontWeight="normal">
                            Weekend
                        </Heading>
                    </TicketsStats.Times>
                </TicketsStats.Details>
            </TicketsStats.Section>
            <TicketsStats.Section>
                <TicketsStats.Title>
                    <CheckIcon boxSize={4}/>
                    <Heading size="sm">
                        Median time until ticket is resolved
                    </Heading>
                </TicketsStats.Title>
                <TicketsStats.Details>
                    <TicketsStats.Times>
                        <Heading size="sm">
                            {formatSeconds(medianTicketResolutionTimeDuringWorkingHours)}
                        </Heading>
                        <Heading size="sm" textColor="blackAlpha.600" fontWeight="normal">
                            Working days
                        </Heading>
                    </TicketsStats.Times>
                    <TicketsStats.Times>
                        <Heading size="sm">
                            {formatSeconds(medianTicketResolutionTimeDuringOffWorkHours)}
                        </Heading>
                        <Heading size="sm" textColor="blackAlpha.600" fontWeight="normal">
                            Weekend
                        </Heading>
                    </TicketsStats.Times>
                </TicketsStats.Details>
            </TicketsStats.Section>
        </TicketsStats.Root>
    );
}

export default TicketsStats;

TicketsStats.Root = styled.div`
    margin-top: 16px;
    margin-left: 32px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 30px;
`;

TicketsStats.Section = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--chakra-space-1);
`;

TicketsStats.Title = styled.div`
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
`;

TicketsStats.Details = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    border-width: 2px;
    border-radius: 10px;
    padding: 20px;
    margin-top: 4px;
`;

TicketsStats.Times = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
`;