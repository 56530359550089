//! @ngInject
export function payrollSetupsSetupModalCtrl($scope, $rootScope, $uibModalInstance, fields, data, DatabaseApi, toaster, FormService) {

    if ($scope.fields === undefined && data && !data.parsedToMinutes) {
        data.parsedToMinutes = true;
        if (data.betweenVisitsTravelTimeMinSeconds) {
            data.betweenVisitsTravelTimeMinSeconds = data.betweenVisitsTravelTimeMinSeconds / 60
        }
        if (data.betweenVisitsTravelTimeMaxSeconds) {
            data.betweenVisitsTravelTimeMaxSeconds = data.betweenVisitsTravelTimeMaxSeconds / 60
        }
    }
    $scope.fields = FormService.getFields(fields, data);
    $scope.form = {};

    $scope.submitForm = function () {
        if ($scope.form.data.$invalid) {
            return;
        }

        $scope.formSending = true;
        const newData = FormService.prepareFormData($scope.fields);
        newData.agencyId = $rootScope.agencyId;


        if (Array.isArray(newData.betweenVisitsTravelTimeCertifications)) {
            newData.betweenVisitsTravelTimeCertifications = newData.betweenVisitsTravelTimeCertifications.map(item => item.id !== undefined ? item.id : item);
        } else {
            newData.betweenVisitsTravelTimeCertifications = [newData.betweenVisitsTravelTimeCertifications];
        }

        if (Array.isArray(newData.spreadOfHoursCertifications)) {
            newData.spreadOfHoursCertifications = newData.spreadOfHoursCertifications.map(item => item.id !== undefined ? item.id : item);
        } else {
            newData.spreadOfHoursCertifications = [newData.spreadOfHoursCertifications];
        }

        // Minutes To seconds
        if (newData.betweenVisitsTravelTimeMinSeconds) {
            newData.betweenVisitsTravelTimeMinSeconds = (newData.betweenVisitsTravelTimeMinSeconds * 60)
        }
        if (newData.betweenVisitsTravelTimeMaxSeconds) {
            newData.betweenVisitsTravelTimeMaxSeconds = (newData.betweenVisitsTravelTimeMaxSeconds * 60)
        }

        newData.officeIds = newData.officeIds.map(row => row.id);

        if (data.id) {
            DatabaseApi.put('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/payroll_setups/' + data.id, newData)
                .then(function (res) {
                    toaster.pop('success', "Payroll setup saved");
                    $scope.closeModal();
                }, function (err) {
                    toaster.pop('error', "Something went wrong", "Could not change payroll setup settings")
                }).finally(function () {
                    $scope.formSending = false;
                });
        } else {
            DatabaseApi.post('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/payroll_setups', newData).then(function (res) {
                toaster.pop('success', "Payroll setup saved");
                $scope.closeModal();
            }, function (err) {
                toaster.pop('error', "Something went wrong", "could not add payroll setup");
            }).finally(function () {
                $scope.formSending = false;
            });
        }   
    };
    $scope.fieldChanged = function(item){
        if(item.type.type === "Boolean" && data.id){
            $scope.submitForm();
        }
    }
    $scope.closeModal = function () {
        $uibModalInstance.close('ok');
    };

    $scope.exit = function () {
        $uibModalInstance.dismiss();
    };
};
