import { LocalDateTime, DateTimeFormatter, nativeJs } from "js-joda";
import moment from "moment";

//! @ngInject
export function billingPayrollsCtrl($scope, NgTableParams, $state, $filter, itemSearchPageManager, toaster, FilterUtils, $uibModal, DatabaseApi, SweetAlert, Consts, $http, $rootScope) {

    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 7);

    $scope.tableParams = null;
    $scope.exportCaregivers = {
        method: null,
        filters: {
            from: fromDate,
            to: new Date(),
            offices: [],
            certifications: []
        },
    };

    $scope.calendarObject = {
        tillDate: false,
    };

    $scope.toggleCalendarObject = function (prop) {
        $scope.calendarObject[prop] = !$scope.calendarObject[prop];
    };

    $scope.isCaregiversExportLoading = false;
    let payrollSetups = DatabaseApi.payrollSetups();
    let exportCaregiversModal;
    function initialize() {
        initExportCaregiverOffices();
        initAgencyCertifications();
        initPageManager();
        $scope.pageManager.resetSearchParamsByUrl();

        // Load filters
        const storageFilters = FilterUtils.rootFilters.billingPayrollsCtrl;
        if (storageFilters !== undefined) {
            $scope.pageManager.setSearchParams(storageFilters);
        }

        $scope.loadItems();
    }

    $scope.$on("$destroy", function() {
        const filters = $scope.pageManager.searchParams;
        FilterUtils.rootFilters.billingPayrollsCtrl = angular.copy(filters);
    });

    $rootScope.$on("got_payroll_setups", function() {
        payrollSetups = DatabaseApi.payrollSetups();
    });

    $rootScope.$on("got_offices", function() {
        initExportCaregiverOffices();
    });

    $rootScope.$on("got_agency_certifications", (_) => {
        initAgencyCertifications();
    });

    function initAgencyCertifications() {
        $scope.certifications = DatabaseApi.agencyCertifications().filter(c => c.isActive).map((certificationItem, index) => ({
            id: certificationItem.certification,
            name: certificationItem.certification
          }));
        $scope.exportCaregivers.filters.certifications = angular.copy($scope.certifications);
    }

    function initExportCaregiverOffices() {
        $scope.offices = DatabaseApi.offices().filter(office => office.active);
        $scope.exportCaregivers.filters.offices = angular.copy($scope.offices);
    }

    var initPageManager = function () {
        $scope.pageManager = itemSearchPageManager.createSearchPageManager(
          "/payroll_batches"
        );

        $scope.officeDataManager = $scope.pageManager.getOfficeDataManager();
        $scope.contractTypesDataManager = $scope.pageManager.getContractTypesDataManager();

        $scope.pageManager.initFromToDateParams();
        $scope.pageManager.initOfficeParam();
        $scope.pageManager.initSearchParam(
          "payrollBatchId",
          $state.params.batchId ? $state.params.batchId : "",
          { isEntity: true }
        );
    };

    var initTable = function (items) {
        var hasItems = items && items.length;
        if (hasItems) {
            // toaster.pop("success", items.length + " Payroll batches were loaded successfully");
        }

        const options = {
            count: 25,
            sorting: { id: "desc" }
        };
        $scope.tableParams = new NgTableParams(options, {
            dataset: items
        });
    };

    $scope.loadItems = function () {
        $scope.pageManager.executeSearch().then(function (response) {
            const batches = response.data.payrollBatches;
            const lastBatchBySetup = filterLastBatches(batches);
            batches.forEach(batch => {
                const payrollSteup = payrollSetups.find(setup => setup.id === batch.payrollSetupId);
                batch.payrollSetupName = payrollSteup.name;
                batch.officeName = payrollSteup.officeIds.map(officeId => $scope.offices.find(office => office.id === officeId).name).join(", ");
                batch.enableDelete = isDeleteEnable(batch, lastBatchBySetup);

                return batch;
            });

            initTable(batches);
        }, function (error) {
            toaster.pop('error', 'Failed to load payroll batches');
        });
    };

    const filterLastBatches = (payrollBatches) => {
        const uniqueSetupId = [...new Set(payrollBatches.map(batch => batch.payrollSetupId))];

        return uniqueSetupId.map(setupId => payrollBatches.filter(payrollBatch => payrollBatch.payrollSetupId === setupId)).map(setupBatch => setupBatch.sort((a, b) => moment(b.createdAt) > moment(a.createdAt) ? 1 : -1)[0].id);
    }

    const isDeleteEnable = (payrollBatch, lastBatchBySetup) => {
        const hoursRange = 24;

        return (payrollBatch.isDraft || ((moment().diff(payrollBatch.createdAt, 'h') <  hoursRange) &&  lastBatchBySetup.includes(payrollBatch.id)));
    }

    $scope.clickTableRow = function (item) {
        if (!item) return;
        $state.go('app.payroll.batch', { id: item.id, payrollBatch: item, isDraft: item.isDraft })
    };

    $scope.generateNewPayrollBatch = function () {
        $scope.isDataLoaded = false;

        var modalInstance = $uibModal.open({
            templateUrl: 'admin/views/billing-new-payroll-batch-modal.html',
            size: 'md',
            controller: 'billingNewPayrollBatchModalCtrl',
            windowTopClass: "billing-new-payroll-batch-modal",
            resolve: {
                payrollSetups: function () {
                    return (DatabaseApi.payrollSetups());
                }
            }
        });
        modalInstance.result.then(function (response) {
            if (response) {
                if (response.isError) {
                    toaster.pop("error", response.message);
                    $scope.isDataLoaded = true;
                }
                else {
                    toaster.pop("success", "New payroll batches were generated successfully");
                    $scope.loadItems();
                }
            }
            else {
                $scope.isDataLoaded = true;
            }
        }, function (rejection) {
            if (rejection !== "backdrop click") {
                toaster.pop("error", "Failed to open modal to generate new payroll batches");
            }
            $scope.isDataLoaded = true;
        });

    };

    $scope.handleDeletePayrollBatch = (event, payrollBatchId) => {
        event.stopPropagation();
        SweetAlert.swal({
            title: "Remove payroll batch",
            text: `Pay Attention!
                   After you remove this payroll batch you will not be able to use it.

                   Are you sure you want to remove it?`,
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3077EB",
            confirmButtonText: "Yes, remove",
            closeOnConfirm: true,
            closeOnCancel: true
        }, (isConfirm) => {
            if (isConfirm) {
                $http.delete(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/payroll_batches/${payrollBatchId}`).then(res => {
                    toaster.pop(
                    "success",
                    "Success",
                    "Payroll batch removed"
                    );
                    $scope.loadItems();
                }).catch(err => {
                    toaster.pop(
                    "error",
                    "Something went wrong",
                    "Failed to remove payroll batch");
                });
            }
        });
    }

    function getExportCaregiversUrlParams() {
        const params = {};

        const areOfficesFiltered = $scope.exportCaregivers.filters.offices.length < $scope.offices.length;
        const areCertificationFiltered = $scope.exportCaregivers.filters.certifications.length < $scope.certifications.length;
        const hasEffectiveDateFilter = $scope.exportCaregivers.method === "effective-date";

        if (areOfficesFiltered) {
            params.offices = $scope.exportCaregivers.filters.offices.map(o => o.id).join("|");
        }

        if (areCertificationFiltered) {
            params.certifications = $scope.exportCaregivers.filters.certifications.map(o => o.id).join("|");
        }

        if (hasEffectiveDateFilter) {
          params.from = LocalDateTime.from(
            nativeJs($scope.exportCaregivers.filters.from)
          ).toLocalDate().format(DateTimeFormatter.ISO_LOCAL_DATE);
        }

        if (hasEffectiveDateFilter) {
            params.to = LocalDateTime.from(
                nativeJs($scope.exportCaregivers.filters.to)
            ).toLocalDate().format(DateTimeFormatter.ISO_LOCAL_DATE);
        }

        return new URLSearchParams(params).toString();
    }

    var universalBOM = "\uFEFF";

    $scope.submitExportCaregivers = (fileType) => {
        $scope.isCaregiversExportLoading = true;

        let url = "agencies/:agencyId/agency_members/:agencyMemberId/payroll_batches_caregivers_report"
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId);

        const params = getExportCaregiversUrlParams();

        if (params !== "") {
            url += "?" + params;
        }

        DatabaseApi.get(url).then((res) => {
            const lines = res.data.lines;
            let csvContent = "";
            lines.forEach(function (row) {
                csvContent += row + "\r\n";
            });
            const encodedUri = 'data:text/csv;charset=utf-8,' + encodeURIComponent(universalBOM+csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", getFileName(fileType));
            document.body.appendChild(link);

            link.click();
                toaster.pop("success", "Success", "Successfully exported");
        }).catch(() => {
            toaster.pop("error", "Something went wrong", "Failed to export");
        }).finally(() => {
            $scope.isCaregiversExportLoading = false;
        });
    }

    $scope.openExportCaregiversModal = () => {
        exportCaregiversModal = $uibModal.open({
            templateUrl: 'admin/views/export-caregivers-modal.html',
            size: 'lg',
            scope: $scope,
            resolve: {},
            // backdropClass: 'transparent-backdrop',
            // windowClass: "modal modal-slide-in-right uib-side-modal"
        });

        return exportCaregiversModal;
    }

    $scope.closeExportCaregiversModal = () => exportCaregiversModal.close();

    const getFileName = (fileType) => {
        return `caregivers-report-${$filter("date")(new Date(), "yyyy-MM-dd")}.${fileType}`;
    }

    initialize();
};