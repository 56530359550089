import { LocalDate, LocalDateTime, LocalTime, nativeJs, DayOfWeek } from "js-joda";
import moment from "moment";

//! @ngInject
export function dutySheetsCtrl(
    $scope,
    $rootScope,
    toaster,
    DatabaseApi,
    $uibModal,
    entityNoteService,
    noteConsts,
    mfModal,
    $filter,
    $q,
) {

    function initialize() {
        $scope.caregiversMap = DatabaseApi.caregivers();
        $scope.patientsMap = DatabaseApi.patients();
        $scope.planOfCareTypes = DatabaseApi.plansOfCare();
        $scope.payrollSetups = DatabaseApi.payrollSetups();
        checkAssetsState();
        $scope.validHourPattern = "^((0[1-9])|(1[0-2]))([0-5])([0-9])";
        $scope.searchQuery = "";
        $scope.taskRows = [];
    
        $scope.selectedEntity = {
            type: '',
            id: ''
        };
    
        $scope.selectedOppositeEntity = {
            type: '',
            id: ''
        };
        $scope.selectedPayrollSetup = {};
    
        $scope.maxShifts = 0;
        $scope.selectedShift = 0;
        $scope.showOnlyActiveEntities = true;
        $scope.showEntitiesStatusOnSearch = false;

        $scope.currentDay = moment();
        $scope.startOfWeekDate = getStartOfWeekDate($scope.currentDay, $rootScope.visitSettings.calendarStartDayOfTheWeek ?? 0);
        $scope.endOfWeekDate = $scope.startOfWeekDate.clone().add(6, 'day').endOf('day');
        onUpdateDutySheetTable();
    }

    /**
     * @param {moment.Moment} currentDay 
     * @param {number} startDayOfWeek
     * @return {moment.Moment}
     */
    function getStartOfWeekDate(currentDay, startDayOfWeek) {
        const currDayClone = currentDay.clone();
        while (currDayClone.days() != startDayOfWeek) {
            currDayClone.subtract(1, "days");
        }
        return currDayClone;
    }

    $scope.selectShift = (index) => {
        $scope.selectedShift = index;
        cleanDutySheetTable();
        prepareDutySheetTable();
    };

    $scope.filterTaskRows = (task) => !task.hide;

    $scope.searchDutySheet = (q) => {
        q = q.toUpperCase().trim();

        $scope.taskRows
            .forEach(task => task.hide = !task.label.toUpperCase().includes(q) && task.code.toUpperCase() !== q);
    };

    function checkAssetsState() {
        if (Object.keys($scope.caregiversMap).length > 0
            && Object.keys($scope.patientsMap).length > 0) {
            $scope.assetsAreReady = true;
        }
    }

    function initializeDutySheetTaskRows() {
        $scope.taskRows = [];
        if ($scope.patientOfficePOC === undefined) {
            return;
        }

        const duties = $scope.patientOfficePOC.columns;

        for (let duty of duties) {
            if (duty.type !== "Task") {
                continue;
            }

            let days = [];
            days.length = 7;

            $scope.taskRows.push({
                label: duty.label,
                code: duty.code,
                taskId: duty.id,
                days: angular.copy(days)
            });
        }
    }

    function prepareWeekDaysArray(startDate, endDate) {
        // prepare currentWeekTitle
        $scope.currentWeekTitle = startDate.format('M.D.Y') + " - " + endDate.format('M.D.Y');

        // set days array
        let diff = endDate.diff(startDate, 'days');
        $scope.daysArray = []
        for (let i = 0; i <= diff; i++) {
            $scope.daysArray.push(moment(startDate).add(i, 'days'))
        }

        // prepare table headers
        $scope.weekHeaders = [];
        $scope.daysArray.forEach(day => {
            $scope.weekHeaders.push({
                dayOfWeek: day.format('dddd').toUpperCase(),
                title: day.format('dd D'),
                date: day.format('YYYY-MM-DD'),
                duties: []
            });
        });
    }

    function calcMaxShifts() {
        if ($scope.visits === undefined ) {
            $scope.maxShifts = 0;
            $scope.selectedShift = 0;
        } else {
            const daysToShiftsAmountMap = $scope.visits.reduce((accumulator, currentValue) => {
                if (accumulator[currentValue.date] === undefined) {
                    accumulator[currentValue.date] = 1;
                } else {
                    accumulator[currentValue.date]++;
                }
                return accumulator;
            }, {});
            $scope.maxShifts = Math.max(...Object.values(daysToShiftsAmountMap));
        }
    }

    function getWeeklyDutySheets() {
        const entityType = $scope.selectedEntity.type;
        $scope.isLoading = true;

        let entityParam;
        if (entityType === "CAREGIVER") {
            entityParam = `caregiverId=${$scope.selectedEntity.id}`;
        } else {
            entityParam = `patientId=${$scope.selectedEntity.id}`;
        }

        const fromParam = LocalDate.from(nativeJs($scope.startOfWeekDate.toDate()));
        let url = "agencies/:agencyId/agency_members/:agencyMemberId/visits_of_week"
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId);
        url = `${url}?startOfWeek=${fromParam}&${entityParam}`;

        DatabaseApi.get(url).then(function (res) {
            $scope.visits = transformResponse(res.data.visits);
            initWeekTaskList();
            $scope.isLoading = false;
        }, function (err) {
            $scope.isLoading = false;
            toaster.pop('error', 'Something went wrong', 'could not get duty sheets');
        });
    }

    function transformResponse(visits) {
        visits.forEach(visit => visit.isEditable = isAllowedToEdit(visit));

        return visits;
    }

    function isAllowedToEdit(visit) {
        let isAllowed = true;

        if (visit && visit.editPermissions) {
            visit.editPermissions.forEach(permission => isAllowed = isAllowed && $rootScope.isPermittedByKey(permission));
        }

        return isAllowed;
    }

    function cleanDutySheetTable() {
        // clean headers from last table
        $scope.weekHeaders.forEach(day => {
            delete day.visitId;
            delete day.startTime;
            delete day.endTime;
            delete day.isDirty;
        });

        // clean task rows days from last table
        let days = [];
        days.length = 7;

        $scope.taskRows.forEach(taskRow => {
            taskRow.days = angular.copy(days);
        });

        // clean shifts
        $scope.maxShifts = 0;
    }

    function initWeekTaskList() {
        if ($scope.visits.length === 0) {
            return;
        }
        setUniqueEntities();
    }

    function setUniqueEntities() {
        let uniqueEntities = new Map();
        $scope.uniqueEntities = [];

        if ($scope.selectedEntity.type === "PATIENT") {
            for (let visit of $scope.visits) {
                uniqueEntities.set(visit.caregiverId, visit);
            }
        }
        else {
            for (let visit of $scope.visits) {
                uniqueEntities.set(visit.patientId, visit);
            }
        }

        $scope.uniqueEntities = [...uniqueEntities.values()];

        if ($scope.uniqueEntities.length === 1) {
            enrichUniqueEntity($scope.uniqueEntities[0]);
            $scope.handleOppositeEntitySelection($scope.uniqueEntities[0]);
            return;
        }

        $scope.uniqueEntities.forEach(entity => {
            enrichUniqueEntity(entity);
        });
    }

    function enrichUniqueEntity(entity) {
        if ($scope.selectedEntity.type === "PATIENT") {
            const caregiver = $scope.caregiversMap[entity.caregiverId];
            if (caregiver) {
                entity.photoUrl = caregiver.photoUrl || 'admin/images/blank-profile.jpg';
                entity.displayName = caregiver.displayName;
            }
        } else {
            const patient = $scope.patientsMap[entity.patientId];
            if (patient) {
                entity.displayName = patient.displayName;
                entity.firstName = patient.firstName;
                entity.lastName = patient.lastName;
            }
        }
    }

    function filterAndSortVisits(day) {
        const filteredVisits = $scope.visits.filter(v => {
            return v.date === day.date && v[$scope.visitEntityFilterId] === $scope.selectedOppositeEntity.id;
        });
        const visits = filteredVisits.sort((a, b) => {
            if(moment(a.date) < moment(b.date)) {
                return -1;
            }
            return 1;
        });
        return visits;
    }

    function attachClockTimesToHeaders() {
        $scope.weekHeaders.forEach(day => {
            const visits = filterAndSortVisits(day);
            if ($scope.selectedShift >= visits.length) {
                return;
            }
            let visitDay = visits[$scope.selectedShift];

            if (visitDay) {
                day.visitId = visitDay.id;
                day.startTime = visitDay.startTime;
                day.endTime = visitDay.endTime;
                day.isEditable = visitDay.isEditable;

                if (visitDay.clockInTime) {
                    day.clockInTime = moment(visitDay.clockInTime).format('hhmm');
                    day.clockInType =  moment(visitDay.clockInTime).format('A');
                    day.defaultClockInVersion = day.clockInTime + day.clockInType;
                }

                if (visitDay.clockOutTime) {
                    day.clockOutTime = moment(visitDay.clockOutTime).format('hhmm');
                    day.clockOutType =  moment(visitDay.clockOutTime).format('A');
                    day.defaultClockOutVersion = day.clockOutTime + day.clockOutType;
                }
                // After editing day clock params, angular ng-change fire makeVisitDirty function
                // but these initial values for the week are not 'dirty'.
                delete day.isDirty;
            }
        });
    }

    function prepareDutySheetTable() {
        calcMaxShifts();
        // check for opposite caregiverId/patientId id filter
        $scope.visitEntityFilterId = $scope.selectedEntity.type === 'PATIENT' ? 'caregiverId' : 'patientId';
        attachClockTimesToHeaders();

        let patientHasPlanOfCare = true;
        $scope.taskRows.forEach((taskRow, taskRowIndex) => {
            $scope.weekHeaders.forEach((day, dayIndex) => {
                const filteredAndSortedVisits = filterAndSortVisits(day);
                if (filteredAndSortedVisits.length <= $scope.selectedShift) {
                    return;
                }
                const visit = filteredAndSortedVisits[$scope.selectedShift];

                if (visit) {
                    const visitCopy = angular.copy(visit);
                    const isDutyDone = visitCopy.dutiesDone.includes(taskRow.taskId);
                    patientHasPlanOfCare = visitCopy.patientHasPlanOfCare;

                    visitCopy.isDone = isDutyDone;
                    visitCopy.visitId = visit.id;
                    visitCopy.visitDutyid = visit.id + "-" + taskRow.taskId + "-" + dayIndex;
                    visitCopy.dutiesDone = [];
                    $scope.taskRows[taskRowIndex].days[dayIndex] = visitCopy;
                }
            });
        });
        $scope.patientHasPlanOfCare = patientHasPlanOfCare;
    }

    function onUpdateDutySheetTable() {
        $scope.endOfWeekDate = $scope.startOfWeekDate.clone().add(6, 'day').endOf('day');
        prepareWeekDaysArray($scope.startOfWeekDate, $scope.endOfWeekDate);

        // in case there is also selected entity, send request
        if ($scope.selectedEntity.type) {
            getWeeklyDutySheets();
        }
    }

    function handleDutySheetDateChange() {
        $scope.selectedOppositeEntity.id = null;
        $scope.selectedOppositeEntity.type = null;
        cleanDutySheetTable();
        onUpdateDutySheetTable();
    }

    function setEndOfWeekFromPayrollSetup() {
        const endOfWeekDay = $scope.selectedPayrollSetup.endOfWeekDay;

        const startOfWeekDay = endOfWeekDay === 6 ? 0 : endOfWeekDay + 1;
        $scope.selectedPayrollSetup.startOfWeekDay = startOfWeekDay;
        $scope.startOfWeekDate = $scope.startOfWeekDate.clone()
            .day(startOfWeekDay).startOf('day');
    }

    function setEntityWeekDates() {
        setEndOfWeekFromPayrollSetup();
        onUpdateDutySheetTable();
    }

    function setPatientRelevantPOC(patientCurrentOfficeId) {
        const patientOfficePOC = DatabaseApi.plansOfCare().find(poc => poc.officeId === patientCurrentOfficeId);
        if (patientOfficePOC !== undefined) {
            $scope.patientOfficePOC = patientOfficePOC;
        }
        initializeDutySheetTaskRows();
    }

    function setEntityWeekFromPayrollSetupAndPOC() {
        const entityId = $scope.selectedEntity.id;
        let relevantPayrollSetup;
        if ($scope.selectedEntity.type === 'PATIENT') {
            const patient = $scope.patientsMap[entityId];
            setPatientRelevantPOC(patient.currentOfficeId);
            relevantPayrollSetup = $scope.payrollSetups
                .find(ps => ps.isActive === true && ps.officeId === patient.currentOfficeId);
            if (patient && relevantPayrollSetup) {
                if (relevantPayrollSetup) {
                    $scope.selectedPayrollSetup = relevantPayrollSetup;
                    setEntityWeekDates();
                }
            } else {
                $scope.startOfWeekDate = $scope.startOfWeekDate.clone().startOf('isoWeek');
                onUpdateDutySheetTable();
            }

        } else {
            const caregiver = $scope.caregiversMap[entityId];
            relevantPayrollSetup = $scope.payrollSetups
                .find(ps =>
                (ps.isActive === true && caregiver.officeIds.includes(ps.officeId) ||
                    (ps.isActive !== true && caregiver.officeIds.includes(ps.officeId))));
            if (caregiver && relevantPayrollSetup) {
                $scope.selectedPayrollSetup = relevantPayrollSetup;
                setEntityWeekDates();
            } else {
                $scope.startOfWeekDate = $scope.startOfWeekDate.clone().startOf('isoWeek');
                onUpdateDutySheetTable();
            }
        }

        getWeeklyDutySheets();
    }

    const getEditableAndUneditableVisits = (visits) => {
        let editableVisits = [];
        let uneditableVisits = [];
        visits.forEach(visit => {
            if (visit.isEditable) {
                editableVisits.push(visit);
            } else {
                uneditableVisits.push(visit);
            }
        });

        return {
            editableVisits,
            uneditableVisits
        };
    };

    const getVisitsPreview = (visits) => {
        return visits.map((visit, index) => {
            const visitStartLocalDateTime = LocalDateTime.of(
                LocalDate.parse(visit.date),
                LocalTime.parse(visit.startTime)
            );
            const date = $filter("mfShortTime")(visitStartLocalDateTime, ["withDate"]);
            const endTime = $filter("mfShortTime")(visit.endTime);
            return `${index + 1}. ${date} - ${endTime}`;
        }).join("\n");
    };

    const alertUnauthorizedEditVisits = (editableVisits, uneditableVisits) => {
        const subject = "Billed/Paid/Payroll Draft Visits";

        const s = editableVisits.length > 1 ? "s" : "";
        const validatedMessage = editableVisits.length > 0 ? `${editableVisits.length} visit${s} will be updated\n` : ``;
        const mainText = "You are not permitted to update the visits on the next dates:\n\n";
        const datesDetails = getVisitsPreview(uneditableVisits);
        let requiredPermissions = [];
        uneditableVisits.forEach((visit) => {
            if (Array.isArray(visit.editPermissions) && visit.editPermissions.length > 0) {
                requiredPermissions = requiredPermissions.concat(visit.editPermissions);
            }
        });
        const permissionsMap = {
          'edit_paid_visit_instance_info': `Can't edit paid visits`,
          'edit_billed_visit_instance_info': `Can't edit billed visits`,
          "edit_visit_instance_on_payroll_draft": `Can't edit visit on payroll draft`
        };
        requiredPermissions = [...new Set(requiredPermissions)]
        const permissionDetailsPrefix = "\n\nBecause you are missing the next permissions:\n";
        const permissionsDetails = requiredPermissions.map(permissionKey => permissionsMap[permissionKey]).join("\n");
        const message = `${validatedMessage}${mainText}${datesDetails}${permissionDetailsPrefix}${permissionsDetails}`;

        const modal = mfModal.create({
            variant: "danger",
            subject: subject,
            message: message,
            layoutOrder: ["message"],
            confirmLabel: "Ok",
            hideCancelButton: true,
            onComplete: () => modal.close()
        });
    }
    
    $scope.toggleShowOnlyActiveEntities = () => {
        $scope.showOnlyActiveEntities = !$scope.showOnlyActiveEntities;
        $scope.showEntitiesStatusOnSearch = !$scope.showEntitiesStatusOnSearch;
    }

    $scope.handleDutySheetDatePickerChange = () => {
        $scope.startOfWeekDate = moment($scope.dutySheetDatePicker).startOf('isoWeek');
        setEndOfWeekFromPayrollSetup();
        handleDutySheetDateChange();
    }

    $scope.updateDutySheetWeek = (weekDirection) => {
        if (weekDirection === 'previous') {
            $scope.startOfWeekDate = $scope.startOfWeekDate.subtract(7, 'day');
        } else {
            $scope.startOfWeekDate = $scope.startOfWeekDate.add(7, 'day');
        }
        handleDutySheetDateChange();
    }

    $scope.handleEntitySelection = (type, id) => {
        $scope.selectedEntity.type = type;
        $scope.selectedEntity.id = id;
        $scope.entityDisplayText = type === 'PATIENT' ? 'Patient' : 'Caregiver';
        $scope.entityOppositeDisplayText = type === 'PATIENT' ? 'Caregiver' : 'Patient';

        // SET ENTITY Payroll setup, and attach relevant POC according to the office
        setEntityWeekFromPayrollSetupAndPOC();
    }

    $scope.handleEntityDeselect = () => {
        $scope.selectedEntity.id = null;
        $scope.selectedEntity.type = null;
        $scope.selectedPayrollSetup = {};

        $scope.selectedOppositeEntity.id = null;
        $scope.selectedOppositeEntity.type = null;

        // need to clean weekheaders clock times
        cleanDutySheetTable();
    }

    $scope.handleOppositeEntitySelection = (entity) => {
        $scope.uniqueEntities.forEach(u => {
            u.isSelected = false;
        });
        entity.isSelected = true;

        if ($scope.selectedEntity.type === 'PATIENT') {
            $scope.selectedOppositeEntity.id = entity.caregiverId;
        } else {
            $scope.selectedOppositeEntity.id = entity.patientId;
            const patient = $scope.patientsMap[entity.patientId];
            if (patient !== undefined) {
                setPatientRelevantPOC(patient.currentOfficeId);                
            }
        }

        $scope.selectedOppositeEntity.type = $scope.selectedEntity.type === 'PATIENT'
            ? 'Patient' : 'Caregiver';

        // clean duty sheet table
        cleanDutySheetTable();
        // prepare duty sheet table
        prepareDutySheetTable();
    }

    $scope.copyVisitClockTimes = (visit) => {
        const oldVisit = {};
        Object.assign(oldVisit, visit);

        visit.clockInTime = moment(visit.startTime, "hhmm").format('hhmm');
        visit.clockInType = moment(visit.startTime, "hhmm").format('A');

        visit.clockOutTime = moment(visit.endTime, "hhmm").format('hhmm');
        visit.clockOutType = moment(visit.endTime, "hhmm").format('A');

        $scope.makeVisitDirty(
            visit,
            oldVisit,
            ["clockInTime", "clockInType", "clockOutTime", "clockOutType"]
        );
    }
    
    $scope.makeVisitDirty = (newVisit, oldVisit, updatedFields) => {
        if (!newVisit || !oldVisit || !Array.isArray(updatedFields)) return;

        const findDirtyField = updatedFields.find(field =>
            newVisit[field] && newVisit[field] !== oldVisit[field]
        );
        if (findDirtyField !== undefined) {
            newVisit.isDirty = true;
        };
    };

    $scope.copyAllVisitTimes = () => {
        $scope.weekHeaders.forEach(day => {
            if (day.visitId && day.isEditable) {
                $scope.copyVisitClockTimes(day);
            }
        });
    }

    $scope.copyVisitDutiesToRestOfWeek = (visitId) => {
        const visitIndex = $scope.visits.findIndex(v => v.id === visitId);
        if (visitIndex === -1) {
            return;
        }

        const visitDutiesDone = $scope.visits[visitIndex].dutiesDone;

        if (visitDutiesDone.length === 0) {
            return;
        }

        const editedVisitIdsMap = {};
        const visitDate = moment($scope.visits[visitIndex].date);
        // 1. fill every relevant cell
        $scope.taskRows.forEach(taskRow => {
            if (visitDutiesDone.includes(taskRow.taskId)) {
                taskRow.days.forEach(dayTask => {
                    if (dayTask === undefined) {
                        return;
                    }

                    if (moment(dayTask.date).isAfter(visitDate)) {
                        dayTask.isDone = true;
                        editedVisitIdsMap[dayTask.visitId] = true;

                        // enter duties to relevant visit
                        const visit = $scope.visits.find(v => v.id === dayTask.visitId);
                        if (visit) {
                            visit.dutiesDone = angular.copy(visitDutiesDone);
                        }
                    }
                })
            } else {
                taskRow.days.forEach(dayTask => {
                    if (dayTask === undefined) {
                        return;
                    }

                    if (moment(dayTask.date).isAfter(visitDate)) {
                        dayTask.isDone = false;
                        editedVisitIdsMap[dayTask.visitId] = true;
                    }
                });
            }
        });

        // Make updated visits dirty
        $scope.weekHeaders.forEach(day => {
            if (day.isEditable && day.visitId !== visitId && editedVisitIdsMap[day.visitId]) {
                day.isDirty = true;
            }
        });
    }

    $scope.updateDutyToVisit = (visitId, dutyId) => {
        const visitIndex = $scope.visits.findIndex(v => v.id === visitId);
        if (visitIndex === -1) {
            return;
        }

        let dutyIndex = $scope.visits[visitIndex].dutiesDone.indexOf(dutyId);
        if (dutyIndex > -1) {
            $scope.visits[visitIndex].dutiesDone.splice(dutyIndex, 1);
        } else {
            $scope.visits[visitIndex].dutiesDone.push(dutyId);
        }
        $scope.weekHeaders.find(day => day.visitId === visitId).isDirty = true;
    }

    const isVisitBilledPaidOrDraft = (visit) => {
        return visit.isBilled || visit.isPaid || visit.isOnPayrollDraft;
    };

    $scope.saveCurrentDutySheetWeek = async () => {
        const momentLocalDateTimeFormatter = "YYYY-MM-DD hhmm A";
        let isClockVersionDifference = false;

        let body = {
            visits: []
        };

        const relevantVisitInstances = $scope.visits.filter(v => {
            const day = $scope.weekHeaders.find(day => day.visitId === v.id);
            return v[$scope.visitEntityFilterId] === $scope.selectedOppositeEntity.id && day && day.isDirty;
        });
        const {editableVisits, uneditableVisits} = getEditableAndUneditableVisits(relevantVisitInstances);
        if (uneditableVisits.length > 0) {
            alertUnauthorizedEditVisits(editableVisits, uneditableVisits);
        }
        if (editableVisits.length === 0) return;

        const visitsWithPendingTimesheet = editableVisits.filter(isVisitPendingTimesheetByCaregiver);

        for (let visit of editableVisits) {
            const updatedVisit = {
                id: visit.id,
                date: visit.date,
                patientId: visit.patientId,
                caregiverId: visit.caregiverId,
                dutiesDone: visit.dutiesDone,
            }

            // check clock times
            const clockedVisit = $scope.weekHeaders.find(day => day.visitId === visit.id);
            if (clockedVisit) {
                let newClockInTime;
                let newClockOutTime;
                if (clockedVisit.clockInTime || visitsWithPendingTimesheet.length > 0) {
                    if (!clockedVisit.clockInType) {
                        toaster.pop('warning', "Incorrect clock type", '');
                        return;
                    }

                    if (!clockedVisit.clockInTime.match($scope.validHourPattern)) {
                        toaster.pop('warning', "Incorrect clock time", '');
                        return;
                    }

                    newClockInTime = moment(`${clockedVisit.date} ${clockedVisit.clockInTime} ${clockedVisit.clockInType}`, momentLocalDateTimeFormatter);

                    // CHECK FOR CLOCK VERSION DIFF OR PENDING TIMESHEET
                    const updatedClockInVersion = clockedVisit.clockInTime + clockedVisit.clockInType;
                    if (updatedClockInVersion !== clockedVisit.defaultClockInVersion || visitsWithPendingTimesheet.length > 0) {
                        isClockVersionDifference = true;
                        updatedVisit.clockInTime = LocalDateTime.from(nativeJs(moment(newClockInTime)));
                    }
                }

                if (clockedVisit.clockOutTime || visitsWithPendingTimesheet.length > 0) {
                    if (!clockedVisit.clockOutType) {
                        toaster.pop('warning', "Incorrect clock type", '');
                        return;
                    }

                    if (!clockedVisit.clockOutTime.match($scope.validHourPattern)) {
                        toaster.pop('warning', "Incorrect clock time", '');
                        return;
                    }

                    newClockOutTime = moment(`${clockedVisit.date} ${clockedVisit.clockOutTime} ${clockedVisit.clockOutType}`, momentLocalDateTimeFormatter);
                    let overNightChange = false;
                    // CHECK FOR OVERINIGHT CLOCK CHANGE
                    if (newClockInTime && !newClockInTime.isBefore(newClockOutTime)) {
                        newClockOutTime.add(1, 'days');
                        overNightChange = true;
                    }

                    // CHECK FOR CLOCK VERSION DIFF OR PENDING TIMESHEET OR OVERNIGHT CHANGE
                    const updatedClockOutVersion = clockedVisit.clockOutTime + clockedVisit.clockOutType;
                    if ((updatedClockOutVersion !== clockedVisit.defaultClockOutVersion) || visitsWithPendingTimesheet.length > 0 || overNightChange) {
                        isClockVersionDifference = true;
                        updatedVisit.clockOutTime = LocalDateTime.from(nativeJs(moment(newClockOutTime)));
                    }
                }
            }
            body.visits.push(updatedVisit);
        }

        if (visitsWithPendingTimesheet.length > 0) {
            const modalResult = await modalVisitsWithPendingTimesheetResult(visitsWithPendingTimesheet);
            if (modalResult !== "ACCEPT") {
                return;
            }
        }

        // check at least visit with clock change
        if (isClockVersionDifference || visitsWithPendingTimesheet.length > 0) {
            const modalInstance = $uibModal.open({
                templateUrl: "admin/views/approve-duty-sheets-clock-times-modal.html",
                size: "md",
                controller: "approveDutySheetsClockTimesModalCtrl",
                resolve: {
                    requestNoteText: () => {
                        const editableVisitsAmount = editableVisits.length;
                        const billedPaidDraftVisitsAmount = editableVisits.filter(isVisitBilledPaidOrDraft).length;
                        if (billedPaidDraftVisitsAmount === 0) {
                            return undefined;
                        }
                        const restText = `billed, paid or on payroll draft. Please post a note in order to confirm edit.`;
                        return billedPaidDraftVisitsAmount === 1
                            ? billedPaidDraftVisitsAmount === editableVisitsAmount
                                ? `The visit instance is ${restText}`
                                : `One of the visit instances is ${restText}`
                            : billedPaidDraftVisitsAmount === editableVisitsAmount
                                ? `The visit instances are ${restText}`
                                : `Some of the visit instances are ${restText}`;
                    },
                    changeToTimesheetStatus: () => visitsWithPendingTimesheet.length > 0 ? "DECLINED" : undefined,
                }
            });

            modalInstance.result.then(function (res) {
                const {approveDutySheetNote, approvalNote} = res;
                if (approveDutySheetNote && (approveDutySheetNote.note || approveDutySheetNote.manualClockTimeEditApproved)) {
                    const visitApprovalNote = approvalNote
                        ? entityNoteService.buildEntityNoteRequest(
                            approvalNote,
                            noteConsts.NoteTypes.ADJUSTMENT_APPROVAL,
                        ) : undefined;

                    for (let updatedVisit of body.visits) {
                        const actors = {
                            patientId: updatedVisit.patientId,
                            caregiverId: updatedVisit.caregiverId
                        };

                        updatedVisit.manualClockNote = entityNoteService.buildEntityNoteRequest(
                            approveDutySheetNote.note,
                            noteConsts.NoteTypes.VISIT_MANUAL_CLOCK_TIME,
                            actors
                        );
                        updatedVisit.manualClockTimeEditApproved = approveDutySheetNote.manualClockTimeEditApproved;

                        const editableVisit = editableVisits.find(visit => visit.id === updatedVisit.id);
                        if (
                            visitApprovalNote !== undefined &&
                            editableVisit !== undefined &&
                            isVisitBilledPaidOrDraft(editableVisit)
                        ) {
                            updatedVisit.approvalNote = visitApprovalNote;
                        }
                    }

                    submitDutySheets(body);
                }
            });

            return;
        }

        submitDutySheets(body);
    }

    function submitDutySheets(body) {
        const url = "agencies/:agencyId/agency_members/:agencyMemberId/visits_of_week"
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId);

        $scope.isCreatingDutySheet = true;
        DatabaseApi.post(url, body).then(function (res) {
            $scope.isCreatingDutySheet = false;
            $scope.$broadcast("cleanSelection");
            toaster.pop('success', "Success", 'Duty Sheets for the current week were created successfully');
        }, function (err) {
            $scope.isCreatingDutySheet = false;
            toaster.pop('error', 'Something went wrong', "Couldn't create duty sheets for this week");
        });
    }

    const isVisitPendingTimesheetByCaregiver = (visit) => {
        if (visit.timesheetStatus !== "PENDING") {
            return false;
        }
        const isClockInTimesheetByCaregiver = (
            visit.clockInType === "TIME_SHEET" &&
            visit.clockInUpdatedByUserType === "CAREGIVER"
        );
        const isClockOutTimesheetByCaregiver = (
            visit.clockOutType === "TIME_SHEET" &&
            visit.clockOutUpdatedByUserType === "CAREGIVER"
        );
        return isClockInTimesheetByCaregiver || isClockOutTimesheetByCaregiver;
    };

    const modalVisitsWithPendingTimesheetResult = (visits) => {
        const deferred = $q.defer();
        const visitsPreview = getVisitsPreview(visits);
        const message = [
            "Clock in/out for the following visit/s were submitted by the caregiver through the mobile's time sheet:",
            visitsPreview,
            "if you wish to proceed, these visits will be automatically Declined from the Time Sheets Approval page"
        ].join("\n\n");
        const modal = mfModal.create({
            subject: "Warning",
            message: message,
            variant: "warning",
            confirmLabel: "Accept",
            cancelLabel: "Cancel",
            onCancel: () => deferred.resolve("CANCEL"),
            onConfirm: () => deferred.resolve("ACCEPT"),
            onComplete: () => modal.close(),
        });

        return deferred.promise;
    };

    $rootScope.$on("got_plan_of_care_type", function (event) {
        $scope.planOfCareTypes = DatabaseApi.plansOfCare();
    });

    $rootScope.$on("got_caregivers_data", function (event) {
        $scope.caregiversMap = DatabaseApi.caregivers();
        checkAssetsState();
    });

    $rootScope.$on("got_patients_data", function (event) {
        $scope.patientsMap = DatabaseApi.patients();
        checkAssetsState();
    });

    $rootScope.$on("got_payroll_setups", function (event) {
        $scope.payrollSetups = DatabaseApi.payrollSetups();
    });

    initialize();
};
